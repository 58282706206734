/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import 'theming.scss';

$top-bar-height: 52px;
$top-bar-total: 109px;
$top-extended-height: 380px;
$top-extended-height2: 430px;

$heading2-font-size: 22px;



html, body {
    position: fixed;
    height: 100vh;
    height: -webkit-fill-available;
    width: 100vw;
    overflow: hidden;
}

body {
    margin: 0;
    padding: 0;
    font-family: $main-font; 
    color: $text-color;

}

/** Scrollbars **/
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,20,0.15) transparent;

    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
        position: absolute;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,20,0.15);
    }    

    &:hover {
        scrollbar-color: rgba(0,0,0,0.3) transparent;
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0,0,0,0.3);
        }    
    }
}

input[type=text], input[type=button] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}


h1, h2, h3, h4, h5 {
    font-family: $header-font!important;
}
h2 {
    padding-top:30px;
    padding-bottom:20px;
}

a {
    color:$primary-color5;
    text-decoration: none;
}

a:hover {
    color:$primary-color6;
}

// Editor-js-viewer component?
.main-paragraph {
    line-height: 24px;
    font-size: 15px;
    font-weight: 300;
}
.main-paragraph b{
    font-weight: 590;
}



/// Blue label e.g. Story

.top_heading_label {
    color: $primary-color3;
    letter-spacing: 0.1em;
    padding-bottom: 10px;
    padding-top: 48px;
    font-size: 14px;
    line-height: 1.2em;
    font-weight: 700;
}

.top_heading_label_no_padding {
    color: $primary-color2;
    letter-spacing: 0.1em;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.2em;
    font-weight: 700;
}

/// Big gray heading

.top_heading_w_label {
    color: #333333;
    letter-spacing: 0.1em;
    padding-bottom: 5px;
    margin-bottom: 12px;
    font-size: 40px;
    line-height: 1.2em;
    font-weight: 700;
}

.no-subheading {
    color: #333333;
    font-style: italic;
}

///************************************************************************************************************/////


ul.image-list-new {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    li {
        width: 280px;
        height: 340px;
        border-radius: 5px;

        background: rgb(255,255,255);

        margin-bottom: 40px;
        margin-right: 1em;

        box-sizing: border-box;
        position: relative;

        cursor: pointer;
        //box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.25);

        transition: box-shadow 0.1s ease-in-out;

        a {
            text-decoration: none;
            overflow: hidden;

            img {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                object-fit: cover;
                width: 100%;
                height: 100%;
                max-height: 170px;


            }

            .title {
                font-family: $header-font;

                font-weight: 900;
                font-size: 15px;
                line-height: 20px;


                color: $primary-color2;
                padding-left: 0.9em;
                padding-right: 0.9em;
                padding-top: 0.8em;
                padding-bottom: 0.5em;
            }

            .img-tagline {
                font-family: $main-font;

                font-weight: 400;
                font-size: 12px;
                line-height: 16px;


                color: #1f3238;
                padding-left: 1em;
                padding-right: 1em;
                padding-bottom: 1em;
                padding-top: 0em;     
            }
        }

        &:hover {
           // box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.70);
           box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
            
           img {
            filter: brightness(120%);
           }

        }

        animation: catalog-appears 0.25s ease-out;
        animation-fill-mode: both;
        @for $i from 1 through 100 {
            &:nth-child(0n + #{$i}) {
                animation-delay: 0.01s*$i;
            }
        }
    }
}


@media screen and (max-width: $mobile-width-limit) {
    ul.image-list-new {
        li {
            width: 340px;
            height: 340px;
        }
    }
}


// Used on front page
ul.image-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    li {
        width: 270px;
        height: 340px;

        background: rgb(255,255,255);
        border-radius: 5px;

        margin-bottom: 1em;
        margin-right: 1em;

        box-sizing: border-box;
        position: relative;

        cursor: pointer;
        box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.25);
        transition: box-shadow 0.1s ease-in-out;

        a {
            text-decoration: none;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                max-height: 340px;
                border-radius: 5px;

            }

            .icon {
                position: absolute;
                right: -5px;
                top: 5px;
                color:#e5b35a;
                //transform: translate(-50%, -50%);
            }

            .title {
                border-radius: 0px 3px 3px 0px;
                font-family: $header-font;
                color: $primary-color5;
                font-size: 18px;
                font-weight: 400;
                position: absolute;
                bottom: 1em;
                background: rgba(255,255,255);
                padding: 0.5em 1em;
                text-align: left;
                width:100%;
            }
        }

        &:hover {
            box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.70);
        }

        animation: catalog-appears 0.25s ease-out;
        animation-fill-mode: both;
        @for $i from 1 through 100 {
            &:nth-child(0n + #{$i}) {
                animation-delay: 0.01s*$i;
            }
        }
    }
}

.block1 {
    margin-bottom: 70px;
}



///************************************************************************************************************/////
///********** Used for the two column layout on most pages ****************************************************/////

.format-column1 {
    -ms-overflow-style: none;
    padding-bottom: 2em;
}


.format-column1-content {
    padding-left: 100px;
    padding-right: 100px;

    
 
    @media screen and (max-width: $ipad-width-limit) {
        padding-left: 50px;
        padding-right: 50px;
    }

    @media screen and (max-width: $mobile-width-limit) {
        padding-left: 1em;
        padding-right: 1em;
    }
}

.format-column2  {
    padding-left: 0px;
    padding-bottom: 100px;
    margin-left : 5px;
    padding-right: 100px;
    background-color : white;
   // min-width: 20rem;
   // max-width: 20rem;
    -ms-overflow-style: none;
}

.margin-column {
    padding-left: 20px;
    padding-right: 20px;   
}

.format-mobile  {
    padding-left: 0px;
    padding-right: 0px;
    -ms-overflow-style: none;
}
.format-one-container {
    display:flex;
    background-color : $background-color-light;
    flex: 1 1 auto;
}

.format-one-column {
    background-color: $background-color-light;
    margin-bottom: 0px;
    width:100%;
}

.format-one-column1 {
    background-color: $background-color-light;
    padding-bottom: 100px;
    margin-bottom: 50px;
    width:auto;
}

.format-one-column2  {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 100px;
    margin-left : 5px;
    max-width: 850px;
    padding-right: 40px;
    background-color : $background-color-light;
    max-width: 270px;
    width:270px;
}


///************************************************************************************************************/////

.top_heading {
    color: #333333;
    letter-spacing: 0.1em;
    padding-bottom: 30px;
    margin-top: 48px;
    margin-bottom: 12px;
    font-size: 40px;
    line-height: 1.2em;
    font-weight: 700;

}

.top_heading2 {
    color: #333333;
    letter-spacing: 0.1em;
    padding-bottom: 30px;
    margin-top: 18px;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
}

.edit_project_heading {
    margin-top: 10px;
    width:100%;

    max-width: 600px;
}

.edit_project_heading2 {
    width:100%;
    font-size:$heading2-font-size;
    max-width: 600px;
}

.top_row {
    margin-bottom: 30px;
    margin-left: 30px;
    text-align: left;
}

.top_row_margin {
    margin-top:30px;
    margin-bottom: 30px;
    margin-left: 30px;
    text-align: left;
}

.top_row_no_left {
    margin-bottom: 30px;
    text-align: left;
}

.next_row {
    margin-left: 30px;
    text-align: left;
}

.input_header {
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
}

.input_description {
    color:rgb(131, 131, 131);
}

.input_field {
    width:100%;
    max-width: 800px;
}

.description_field {
    width:100%;
    max-width: 800px;
    min-height: 200px;
}

.input_form_field_small {
    width:100%;
    max-width: 800px;
}

.html_view .ql-editor {
    font-family: $main-font !important;
    padding:0px !important;
}

.ql-editor {
    background-color: white ;
    line-height: 1.8!important;
}

#storyview .ql-editor {
    font-size:16px;
    line-height: 3rem;
}

.ql-toolbar {
    background-color: white ;
}

.flex-container {
    display: flex;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    position:relative;
}

.format-column {
    background-color: #fFFFFF;
    padding-left: 40px;
    padding-right: 10px;
    min-height:1000px;
}

.icon {
    position: relative;
    /* Adjust these values accordingly */
    top: 5px;
    margin-right: 15px;
}

#content {
    padding-left:0px;
    margin-left:0px;
    transition: all 0.3s;
    width: calc(100% - 21em);
    position: relative;
    left: 21em;
    z-index: 1;
}

.outer-class {
    text-align: left;
    position: relative;
    width:100%;
}

.format-outer-column {
    background-color: #fFFFFF;
    min-height:calc(100vh - #{$top-bar-total});
    padding-top: 0px;
    max-width: 3000px;
    box-shadow: 0px 3px 5px 1px rgba(179,179,179,0.6);
}

.format-inner-column {
    padding-left: 40px;
    padding-right: 10px;
}

.top-image-cover-div {
    width: 100%;
    position: relative;
}

.top-image-cover {
    display: block;
    object-fit: cover;
    border-radius: 0px;
    width: 100%;
    filter: brightness(70%);
    opacity: 1;
    object-position: center center;
}

.top-image-cover-overlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 45px;
    
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 0;

    
}

.top-image-cover-overlay-frontpage {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 45px;
    background-color: rgba(0, 0, 0, 0.60);
    //background: url(/assets/img/storyback.png);
    filter: brightness(70%);
    z-index: 0;
}

/* Position child elements relative to this element */
.aspect-ratio-box {
    position: relative;
}

/* Create a pseudo element that uses padding-bottom to take up space */
.aspect-ratio-box::after {
    display: block;
    content: '';
    /* 16:9 aspect ratio */
    padding-bottom: 56.25%;
}

/* Image is positioned absolutely relative to the parent element */
.aspect-ratio-box img {
    /* Image should match parent box size */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* Position child elements relative to this element */
.aspect-ratio-box2 {
    position: relative;
}

/* Create a pseudo element that uses padding-bottom to take up space */
.aspect-ratio-box2::after {
    display: block;
    content: '';
    /* 32:9 aspect ratio */
    padding-bottom: 28.12%;
}

/* Image is positioned absolutely relative to the parent element */
.aspect-ratio-box2 img {
    /* Image should match parent box size */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tagline {
    font-family: $header-font;

    padding-top: 8px;
    font-size:22px;
    line-height: 1.5em;
    padding-bottom: 12px;
}




.menu_selected {
    background:#4f5571;
    color:#FFFFFF;
}

.hr-line {
    background-color: #E5E5E5;
}


/* Hides the mobile menu on non mobile devices */
.top-menu-mobile {
    display: none;
}


.logo-text1 {
   // font-family: 'Inter';
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;

    margin-left:0px;
    margin-bottom:5px;
    color: black;
    font-weight: 400;
    font-size: 24px;
    line-height: 16px;
    color: $primary-color2;
}

.logo-text2 {
    font-weight: 650;
    font-size: 16px;
    line-height: 16px;
    color: #595959;
}



.break-long-texts {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

/*********** Media Queries **************/

@media (max-width: 768px) {
    #content {
        left: 0px;
        width: 100%;
    }

    .format-inner-column {
        padding-left: 7px;
        padding-right: 7px;
    }
}

.divider {
    box-shadow: 0px 3px 5px 1px rgba(179,179,179,0.6);
    padding-right: 50px;
    -ms-overflow-style: none;
}

.divider::-webkit-scrollbar {
    display: none;
}


.format-inner-column2 {
    padding-left: 40px;
    padding-right: 10px;
}


.drag-n-drop--highlight {
    box-shadow: 0 0 1.5em blue !important;
}


div.outer-image-top {
    background: rgb(255,255,255);
    margin-bottom: 1em;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 12em;
    max-height: 15em;

    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    transition: box-shadow 0.1s ease-in-out;

    div.inner-image {
        text-decoration: none;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            min-height: 12em;
            max-height: 15em;
        }

        .image-inline-text
        {
            position: absolute;
            top: 5em;
            width:100%;
            padding-left:30px;
            padding-right:30px;
            .title {
                
                font-family: $header-font;
                font-weight: 500;
                color: white;
                line-height: 1.2em;
                font-size: 28px;
                line-height: 40px;

            }

            .tagline {
                font-family: $header-font;
                font-weight: 300;

                font-size: 20px;
                color: white;
                line-height: 1.2em;
            }
        }
    }
}


@media screen and (max-width: $mobile-width-limit) {
    div.outer-image-top div.inner-image .image-inline-text {
        .title {
            line-height: 1.2em;
            font-size: 20px;

        }

        .tagline {
            font-size: 12px;

        }
    }
}


div.outer-image {
    background: rgb(255,255,255);

    margin-bottom: 1em;
    margin-right: 1em;

    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 9em;
    max-height: 12em;

    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    transition: box-shadow 0.1s ease-in-out;

    div.inner-image {
        text-decoration: none;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            min-height: 9em;
            max-height: 12em;
        }

        .title {
            font-family: $header-font;
            font-size: 28px;
            position: absolute;
            top: 0.3em;
            background: rgba(0,0,0,0.5);
            color: white;
            padding: 0.5em 1em;
            text-align: center;
            line-height: 1.2em;
            text-shadow: 0 0 1px black;
        }
    }
}


.page-max-width {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 3000px;
}

.widget {
    margin-bottom:60px;
}

.contribute {
    color: $primary-color1;
    font-size: 11px;
    font-weight: 500;
}

.favor-title {
    font-family: $header-font;

    padding-top: 5px;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
}

.favor {
    margin-top: 25px;
    margin-bottom: 25px;
    border-left: 2px solid $primary-color1;
    padding-left: 10px;
}

.favor-description {
    padding-bottom: 10px;
}

.participate {
    //box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.25);
   // margin-left: -20px;
   // margin-right: -20px;
    position: relative;
    border-color: $primary-color6;
    border-style:solid;
    border-width: 1px;
}

.participate-text {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.participate-heading {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    width:100%;
    background: $primary-color6;
}

.participate-heading-selected {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    width:100%;
    background: $primary-color2;
}

.new-section {
    padding-top:15px;
    padding-bottom: 15px;
}

.favor-icon {
    position: relative;
    top: 3px;
    margin-left: 5px;
    margin-right: 1px;
    font-size: 15px;
}

.admin-style {
    color: $primary-color2;
}

.catalog-link {
    color: #212529;
}

.workshop-heading {
    font-family: $header-font;
    color: $primary-color4;
    font-size: 64px;
    line-height: 82px;
    padding-top: 64px;
    padding-bottom: 64px;
    font-weight: 700;
}

.heading1 {
    font-family: $header-font;
    color: $primary-color4;
    font-size: 64px;
    line-height: 82px;
    padding-top: 64px;
    padding-bottom: 64px;
    font-weight: 700;
}



.workshop-summary {
    font-family: $header-font;
    padding-bottom: 20px;
    font-size: 24px;
    line-height: 36px;
}

.workshop-summary-small {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 14px;
    line-height: 21px;  
}

.favor-heading {
    font-family: $header-font;
    color: $primary-color4;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}


.center {
    margin: auto;
    max-width: 5000px;
}




.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}



.mat-button.mat-primary {
    color: $primary-color5!important;
}
.mat-stroked-button.mat-primary{
    color: $primary-color5!important;
}
.mat-raised-button.mat-primary {
    background-color: $primary-color5!important;
}
.mat-flat-button.mat-primary {
    color: white!important;
    background-color: $primary-color5!important;
}
.button-right {
    width: 150px;
}
.button-right-div {
    text-align: right;
    margin-top: 10px;   
    margin-bottom: 10px;
}

.navbar-toggler {
    margin-right: 5px;
}

/* Flexbox layouting blocks */
.main-view {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    left:0;
    top:0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    //background-image: url("assets/img/background.png");

    .top {
        flex: 0 0 auto;
        overflow: visible;
        z-index: 1;
    }
    .content {
        flex: 1 1 auto;
        flex-direction: column;
        overflow: hidden;
        display: flex;
        align-items: stretch;
        justify-content: stretch;

        /** ------ Provide a number of different full-view reusable layout blocks ------ **/
        
        
        /* This block centers contents horizonatally and vertically */
        .centerblock {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        /* Content is scrolled */
        .scrollblock {
            flex: 1 1 auto;
            overflow-x: hidden;
            overflow-y: auto;
            height: 100%;
            position: relative;
        }

        /* Flex context is passed on to the content itself */
        .flexblock {
            flex: 1 1 auto;
            display: flex;
            height: 100%;
            justify-content: center;
        }

        .splashblock {
            /* Show a splashscreen and some content, 2-column on web, fullscreen on mobile */
            display: flex;

            min-height: 0em;
            height: min(100%,40rem);
            justify-content: flex-start;
            align-items: stretch;

            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
            border-radius: 2px;                
            background-color: #FFFFFF;

            .splashblock-splash, .splashblock-content {
                width: min(50vw,30rem);
            }

            .splashblock-splash {
                pointer-events: none;
            }

            .splashblock-content {
                overflow-y: auto;
                padding: 3rem;

                h1,h2 {
                    color: $primary-color4;                    
                }
                h1 {
                    @extend .top_heading;
                    margin-top: 0;
                }
            }

            @media screen and (max-width: $mobile-width-limit) {
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                height: 100%;

                .splashblock-splash, .splashblock-content {
                    width: min(100vw);
                }
                .splashblock-splash {
                    @keyframes splashblock-splash-mobile {
                        from {opacity:1}
                        to {opacity:0}
                    }
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    animation: splashblock-splash-mobile 0.25s ease-in-out;
                    animation-delay: 1s;
                    animation-fill-mode: both;
                }
            }                
        }
    }
}


ul.workshop-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    li {
        width: 17em;
        height: 17em;
        border-radius: 5px;
        background: rgb(255,255,255);

        margin-bottom: 1em;
        margin-right: 1em;

        box-sizing: border-box;
        position: relative;

        cursor: pointer;
        box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.25);

        transition: box-shadow 0.1s ease-in-out;

        a {
            text-decoration: none;

            .workshop-content {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                height: 13.6em;
                border-style: solid;
                border-width: 5px 0px 0px 0px;
                border-color:  $primary-color2;
                margin-bottom: 10px;
                text-overflow: ellipsis;
                word-wrap: break-word;
                overflow: hidden;

                .workshop-title {
                    font-family: $header-font;

                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;

                    color: $primary-color4;
                    padding-left: 0.9em;
                    padding-right: 0.9em;
                    padding-top: 0.8em;
                    padding-bottom: 0.5em;
                }

                .workshop-tagline {
                    font-family: $main-font;

                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;

                    color: #1f3238;
                    padding-left: 1em;
                    padding-right: 1em;
                    padding-top: 0em;            }
            }

            .workshop-join {
                padding-top: 0.5em;
                border-style: solid;
                border-width: 1px 0px 0px 0px;
                border-color: #D9D9D9;
                padding-left: 0.9em;
                padding-right: 0.9em;
                font-size: 14px;
                line-height: 22px;
                align-items: center;
                text-align: center;
            }
        }

        &:hover {
            box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.70);
            transform: scale(1.02);
        }

        animation: catalog-appears 0.25s ease-out;
        animation-fill-mode: both;
        @for $i from 1 through 100 {
            &:nth-child(0n + #{$i}) {
                animation-delay: 0.01s*$i;
            }
        }
    }
}

.participate {
    .ql-toolbar {
        display:none;
    }

    .ql-container  {
        border: 0px!important;
    }
    .ql-container.ql-snow  {
        border: 0px!important;
    }
    .ql-editor {
        border: 0px solid #dadada !important;
    }
}


.dialog-action-buttons {
    padding-bottom: 40px;
}

.info-description {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    color: $primary-color6;
}

.avatar {
    width: 36px;
    height: 36px;
    border-radius:18px;
    
    &.small {
        width: 16px;
        height: 16px;
    }
}

.avatar-div {
    padding-right: 5px;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.add-button {
    background-color: rgb(57 197 72)!important;
    margin-top: -5px;
}


.editor-js-div {
    position: relative;

    .edit-mouse {
        margin-left:-30px;
        margin-top:-30px;
        display:none;
        position: sticky;
        top: 80px;
        -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
        -o-animation: fadein 1s; /* Opera < 12.1 */
        animation: fadein 1s;

        color:rgb(185, 185, 185);
    }

}

.editor-js-div:hover .edit-mouse {
    display: block;
}


.inspiration-footer {
    margin-left: -40px;
    padding-left: 40px;
    margin-right: -40px;
    padding-right: 40px;
    padding-top: 0;//30px;
}



/*************************************** Headings *********************************************************************/

.heading2-no {
    font-family: $header-font;
    color: $primary-color4;

    padding-top: 36px;
    font-size: 36px;
    line-height: 45px;
    font-weight: 700;

    padding-bottom: 12px;
    margin-bottom: 24px;
}

.heading2 {
    font-family: $header-font;
    color: $primary-color4;

    padding-top: 36px;
    font-size: 36px;
    line-height: 45px;
    font-weight: 700;

    padding-bottom: 12px;
    margin-bottom: 24px;

    border:dashed;
    border-top-width:0px ;
    border-left-width:0px ;
    border-right-width:0px ;
    border-bottom-width:1px;
    border-color: #D9D9D9;
}


.heading3 {
    color: $primary-color4;
    font-family: $header-font;
    padding-bottom: 18px;
    margin-bottom: 18px;
    margin-top: 30px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    border:dashed;
    border-top-width:0px ;
    border-left-width:0px ;
    border-right-width:0px ;
    border-bottom-width:1px;
    border-color: #D9D9D9;
}

.heading3-free {
    color: black;
    font-family: $main-font;
    letter-spacing: 0.1em;
    padding-bottom: 20px;
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 24px;
    line-height: 1.2em;
    font-weight: 400;
}

.heading4 {
    color: $primary-color3;
    font-family: $header-font;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    line-height: 1.2em;
    font-weight: 500;
}

.section-heading {
    color: $primary-color4;
    font-family: $header-font;
    margin-top: 15px;
    font-size: 18px;
    line-height: 2em;
    font-weight: 700;
}


.div-space {
    padding-top:90px;
}

.heading-div-top {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-right:20px;

    color: $primary-color4;
    font-family: $header-font;

    padding-top:20px;
    padding-bottom: 18px;
    margin-bottom: 18px;
    margin-top: 0px;

    font-size: 24px;
    line-height: 30px;
    font-weight: 700;

    border:dashed;
    border-top-width:0px ;
    border-left-width:0px ;
    border-right-width:0px ;
    border-bottom-width:1px;
    border-color: #D9D9D9;

    .heading-title {
        color: $primary-color4;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        position: relative;
        .edit-mouse {
            display:none;
            padding-left: 10px;
            margin-top:-2px;
            color:rgb(185, 185, 185);
            -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: fadein 1s; /* Firefox < 16 */
            -ms-animation: fadein 1s; /* Internet Explorer */
            -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
        }
    }




    .heading-menu {
        position: absolute;
        margin-top:-5px;
        right: 0px;
        z-index: 1;
    }
}


.heading-div {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-right:20px;

    color: $primary-color4;
    font-family: $header-font;

    padding-top:50px;
    padding-bottom: 18px;
    margin-bottom: 18px;
    margin-top: 0px;

    font-size: 24px;
    line-height: 30px;
    font-weight: 700;

    border:dashed;
    border-top-width:0px ;
    border-left-width:0px ;
    border-right-width:0px ;
    border-bottom-width:1px;
    border-color: #D9D9D9;

    .heading-title {
        color: $primary-color4;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        position: relative;
        .edit-mouse {
            display:none;
            padding-left: 10px;
            margin-top:-2px;
            color:rgb(185, 185, 185);
            -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: fadein 1s; /* Firefox < 16 */
            -ms-animation: fadein 1s; /* Internet Explorer */
            -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
        }
    }




    .heading-menu {
        position: absolute;
        margin-top:-5px;
        right: 0px;
        z-index: 1;
    }
}

.heading-title:hover .edit-mouse {
    display: block;
}





ul.task-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    li {
        width: 17em;
        border-radius: 5px;
        height: fit-content;

        background: rgb(255,255,255);

        margin-bottom: 1em;
        margin-right: 1em;

        box-sizing: border-box;
        position: relative;

        cursor: pointer;
        box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.25);

        transition: box-shadow 0.1s ease-in-out;

        .task-content {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-style: solid;
            border-width: 5px 0px 0px 0px;
            border-color:  #e5b35a;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;

            .task-title {
                font-family: $header-font;

                font-weight: 600;
                font-size: 18px;
                line-height: 24px;

                color: $primary-color4;
                padding-left: 0.9em;
                padding-right: 0.9em;
                padding-top: 0.8em;
                padding-bottom: 0.5em;
            }

            .task-tagline {
                font-family: $main-font;

                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                color: #1f3238;
                padding-left: 1em;
                padding-right: 1em;
                padding-top: 0em;            }
        }

        .task-action {
            padding-top: 0.5em;
            border-style: solid;
            border-width: 1px 0px 0px 0px;
            border-color: #D9D9D9;
            padding-left: 0.9em;
            padding-right: 0.9em;
            font-size: 14px;
            line-height: 22px;
            text-decoration: none;

            a {
                height: 45px;
                padding-top:10px;
                width: 100%;
                align-items: center;
                text-align: center;
                display:block;
                text-decoration: none;

            }
            &:hover {
                box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.70);
                transform: scale(1.02);
            }
        }

        .task-final {
            align-items: center;
            text-align: center;

            background-color: #e5b35a;

            a {
                text-decoration: none;
                color: white!important;

                .task-final-icon {
                    padding-top:40px;
                    padding-bottom: 20px;
                }

                .task-final-text {
                    font-size: 18px;
                    padding-bottom: 40px;
                }
            }

            &:hover {
                box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.70);
                transform: scale(1.02);
            }
        }

        animation: catalog-appears 0.25s ease-out;
        animation-fill-mode: both;
        @for $i from 1 through 100 {
            &:nth-child(0n + #{$i}) {
                animation-delay: 0.01s*$i;
            }
        }
    }
}


.task-menu {
    position: absolute;
    top: 8px;
    right: 3px;
    z-index: 1;

}

.ql-container {
    height:auto!important;
}

.grey-icon {
    color: $primary-color6;
}

.admin-section {
    margin-top:-10px;
    padding-left: 40px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    position: relative;

    .admin-right {
        margin-top:-15px;
        position: absolute;
        right: 50px;
        z-index: 1;
    }
}

.gap {
    width: 100%;
    height: 3px;
    background: $background-color;
    margin-bottom: 20px;
}

.two-column {
    column-count: 2;
    padding-bottom: 30px;
    vertical-align: top;
    margin-top:0px;
    column-width: 20em; /* switch to 1-column on mobile or if content is forced */
}

.kblock-menu {
    display: flex;
    align-items: center;
}
.kblock-outer {
    padding-left:10px;
    padding-right: 10px;
    margin-right: 30px;
}

/** Some popups **/
.reinvent-popup {
    // By default popups take shape from their content up to 80% of screen
    max-width: 80vw;
    max-height: 80vh;
    width: auto;
    height: min-content;
    
    @media screen and (max-width: $mobile-width-limit) {
        max-width: 95vw !important;
        max-height: 85vh;
    }        
}


.inspiration-dialog-container {
    // By default inspiration block popups are a fixed size
    width: 822px;
    height: 620px;
        
    .mat-dialog-container {
        padding-bottom: 0px!important;

        @media screen and (max-width: $mobile-width-limit) {
            padding: 0;

            .top-inspiration {
                padding: 0.25em;
            }
            .i-container {
                flex-wrap: wrap;
                .i-column1, .i-column2 {
                    width: 100%;
                    max-width: none;
                    height: auto;
                    padding: 1em;
                }
                .i-column2 {
                    padding: 0;
                }
            }
        }
    }        
}

@media screen and (max-width: $mobile-width-limit) {
    .hidden-on-mobile {
        display:none !important;
    }
}

.menu-heading {
    padding-top: 10px;
    padding-bottom: 10px;
    color: $primary-color3;
    font-family: $header-font;
    font-size: 15px;
    line-height: 1.8em;
    font-weight: 700;
}

@media screen and (max-width: $mobile-width-limit) {
    .mobile-padding-left {
        padding-left:20px;
    }
}

.quill-edit {
    width: 100%;
}
quill-editor {
    width: 100%;
    
    .ql-snow .ql-tooltip {
            left: 50% !important;
            transform: translateX(-50%);
            z-index: 200;
    }    
}

.normal-page-content {
    background: $background-color-light;
    padding-left: 4em;
    padding-right: 4em;
}


@media screen and (max-width: $mobile-width-limit) {
    .normal-page-content{
        background: $background-color-light;
        padding-left: 2em;
        padding-right: 2em;
    }
    
}


.inspiration-adder {
    display: flex;
    justify-content: left;    
    padding-bottom: 50px;   
    .inspiration-floater {
        position: absolute;
        z-index: 900;
        margin-top: 2em;    
 
        
        input {
            max-width: 320px;
            width: 50vw;
            min-width: 10em;
            min-height: 50px;
            border-radius: 0.5em;
            box-shadow: 0.1em 0.1em 0.2em rgb(60, 60, 60);
            padding: .4em 0.75em;
            border: 0;
            
        }
        
        transition: opacity 0.3s ease-in-out;
        
        &.floating {
            position: fixed;
            opacity: 0.6;
            
            &:hover {
                opacity: 1;
            }
        }
    }
}

.ideate-icon { color:white;}


.top-space {
    margin-top: 45px;
}

.page-padding {
    padding-top: 20px;
    padding-bottom: 20px;
}