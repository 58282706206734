/**
 * Theming variables for components
 */

/* Users */
$user-logged-in: #1f6128;
$user-owns-content: #3f51b5;

/* Questionnaires */
$questionnaire-box-background: rgba(255, 255, 255, 0.45);
$barchart-graph-selected: $user-owns-content;
$barchart-graph-normal: var(--secondary);



$primary-color1: #f50057;
$primary-color2: #20ADD8;

$primary-color3: #2A2A2A;
$primary-color4: #1A4F5C;

$primary-color5: #00A7D2;

$primary-color6: #97AFB9;

$background-color: #fafafa;
$background-color-light: #FFFFFF; // #f4f3f3;//#fafafa;




$text-color: $primary-color3;




$main-font: inter, sans-serif;


$header-font: 'Inter', sans-serif;


@mixin word-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }


  ///*************** Mobile or no mobile view ********************************/////

  $mobile-width-limit: 991.98px;
  $ipad-width-limit: 1100px;
  

  @media screen and (min-width: $mobile-width-limit) {
    .mobile {
             display: none;
    }

    }


    @media screen and (max-width: $mobile-width-limit) {

    .no-mobile {
        display: none;
    }

    }

//**************** TAG colours *********************************//
[tag-color="#b80000"] { // Dark red
    --tag-full: #B80000;
    --tag-light: #e6d9d9;
    --tag-text: #912626;    
}
[tag-color="#db3e00"] { // light red
    --tag-full: #DB3E00;
    --tag-light: #ffe0e0;
    --tag-text: #DB3E00; // c41c1c;
}
[tag-color="#fccb00"] { // yellow
    --tag-full: #FCCB00;
    --tag-light: #fff7cf;
    --tag-text: #95790d;
}
[tag-color="#008b02"] { // green
    --tag-full: #008b02;
    --tag-light: #deffd8;
    --tag-text: #3f9b32;    
}
[tag-color="#006b76"] { // Cyan
    --tag-full: #006B76;
    --tag-light: rgb(213, 251, 255);
    --tag-text: #30828a;
}
[tag-color="#1273de"] { // light blue
    --tag-full: #1273DE;
    --tag-light: #e4eeff;
    --tag-text: #7691fb;
}
[tag-color="#004dcf"] { // dark blue
    --tag-full: #004DCF;
    --tag-light: #e1e7ff;
    --tag-text: #575cdd;
}
[tag-color="#5300eb"] { // purple
    --tag-full: #5300EB;
    --tag-light: #ebdbff;
    --tag-text: #8146d7;
}
